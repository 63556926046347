<template>
<section class="px-3">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
        content-style="padding: 0;"
        :segmented="{
          content: true,
        }">
        <template #header>
            <Progress v-if="autoUpdate" class="mb-4" ref="progress" @done="doneTimer" />
            <div class="flex justify-between items-center">
                <div class="text-xs text-left">{{ tools.localization?.['tools/stats/general_info/title'] }}</div>

                  <div class="flex justify-between items-center ">
                      <rb-checkbox
                          :label="refs.localization.autorefresh.autorefresh_label"
                          :checked="autoUpdate"
                          @update:checked="setAutoUpdate" />
                      <n-button
                          size="tiny"
                          class="ml-2 rounded-md text-white/90"
                          :color="gl.mainColor"
                          @click="getStatsFullInfo">
                          {{ refs.localization.autorefresh.autorefresh_btn }}
                      </n-button>
                  </div>
              </div>
        </template>
        <template #default>
            <div class="flex flex-row flex-wrap w-full px-3">
                <div class="text-xs py-4">
                    {{ tools.localization['tools/stats/general_info/bots/active'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.bots.active }}</span> ,
                    {{ tools.localization['tools/stats/general_info/bots/total'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.bots.total }}</span>
                </div>
                <n-divider class="my-0" />

                <div class="text-xs py-4">
                    {{ tools.localization['tools/stats/general_info/finished_cycles/day'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.finished_cycles.day }}</span> ,
                    {{ tools.localization['tools/stats/general_info/finished_cycles/month'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.finished_cycles.month }}</span>
                </div>
                <n-divider class="my-0" />

                <div class="text-xs py-4">
                    {{ tools.localization['tools/stats/general_info/users/day'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.users.day }}</span> ,
                    {{ tools.localization['tools/stats/general_info/users/total'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.users.total }}</span>
                </div>
            </div>
        </template>
    </n-card>

    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md mt-4"
        content-style="padding: 0;"
        :segmented="{
          content: true,
        }">
        <template #header>
            <div class="flex w-full items-center">
                <div class="text-xs text-left" v-html="tools.localization?.['tools/stats/pairs_info/title']"></div>
                <div class="flex flex-grow justify-end">
                    <rb-select
                        :options="exchangeOptions"
                        v-model:value="dataForm.exchange" />
                    <rb-select
                        class="ml-4"
                        :options="statPeriodsOptions"
                        :defaultValue="false"
                        v-model:value="dataForm.stat_period" />
                </div>
            </div>
        </template>
        <template #default>
            <n-data-table
                :bordered="false"
                :single-line="false"
                :columns="tableColumns"
                :data="pairsInfo" />
        </template>
    </n-card>
</section>
</template>

<script>
// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ToolsService from '@services/tools';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useTools } from '@store/tools';

// router
import { useRoute, useRouter } from 'vue-router';

// UI
import {
    NCard,
    NButton,
    NDivider,
    NDataTable,
    useMessage } from 'naive-ui';

// components
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import Progress from '@components/base/progress.vue';
import CellText from '@components/cells/cell-text/mobile.vue';
import CellPair from '@components/cells/cell-pair/mobile.vue';
import CellExchange from '@components/cells/cell-exchange/mobile.vue';

export default {
    name: 'tools-stats-desktop',
    components: {
        NCard,
        NButton,
        RbSelect,
        Progress,
        NDivider,
        NDataTable,
        RbCheckbox,
    },
    setup() {
        // store
        const gl = useGl();
        const refs = useRefs();
        const tools = useTools();

        // ui
        const message = useMessage();

        // i18n
        const { t } = useI18n();

        // vars
        const progress = ref();
        const pairsInfo = ref();
        const generalInfo = ref();
        const autoUpdate = ref(false);
        const dataForm = reactive({
            stat_period: 1,
            exchange: -1,
        });

        watch([
            () => dataForm.stat_period,
            () => dataForm.exchange,
        ], () => {
            getStatsFullInfo();
        });

        const exchangeOptions = computed(() => refs.exchanges.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));

        const statPeriodsOptions = computed(() => tools.statPeriods.map(el => ({
            label: el.title,
            value: +el.id,
            ...el,
        })));

        const columns = computed(() => [
            {
                title: '#',
                key: 'i',
            }, {
                title: tools.localization['tools/stats/pairs_info/pair'],
                key: 'pair',
            }, {
                title: tools.localization['tools/stats/pairs_info/exchange'],
                key: 'exchange',
            },
        ]);

        const tableColumns = computed(() => 
            columns.value.map(el => (
                {
                    key: el.key,
                    order: false,
                    render: (row, i) => {
                        if (el.key === 'i') {
                            return h('div', {
                                class: 'text-center text-xs font-semibold text-gray-600 dark:',
                            }, i + 1);
                        } else if (el.key === 'exchange') {
                            return h(CellExchange, {
                                data: row[el.key],
                            });
                        } else if (el.key === 'pair') {
                            return h(CellPair, {
                                coin: row[el.key],
                                data: row[el.key],
                                dataFooter: row[el.key],
                            });
                        }
                    },
                    ...el,
                }
            )),
        );

        const getStatsFullInfo = async (showLoading = true) => {
            if (showLoading)
                gl.showLoading = true;

            try {
                const data = ( await ToolsService.getStatsFullInfo(dataForm) ).data;

                pairsInfo.value = data.pairsInfo;
                generalInfo.value = data.generalInfo;

            } catch {
                message.error(t('errorMessage'));
            };
            
            if (showLoading)
                gl.showLoading = false;
        };

        const doneTimer = async () => {
            await getStatsFullInfo();

            progress.value.start();
        };

        const setAutoUpdate = (e) => {
            autoUpdate.value = e;
        };

        onMounted( async () => {
            await getStatsFullInfo();
        });

        return {
            gl,
            refs,
            tools,
            progress,
            dataForm,
            pairsInfo,
            autoUpdate,
            generalInfo,
            tableColumns,
            exchangeOptions,
            statPeriodsOptions,
            doneTimer,
            setAutoUpdate,
            getStatsFullInfo,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(b) {
  color: #4949D9;
}

.table-card :deep(.n-card__content) {
  padding: 0;
}

:deep(td) {
    padding: 0 !important;

    & > * {
        padding: 4px;
    }
}
</style>